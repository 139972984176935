import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, shallowReadonly } from '@vue/composition-api'
import { orgsClient } from './clients/orgs'
import { getOrgId } from './sso.js'

Vue.use(VueCompositionAPI)

const _state = reactive({
  billingReports: [],
  orgsMap: {},
  orgs: [],
  org: null,
  orgUsers: [],
  patientReports: [],
  runningReport: false,
})

export const state = shallowReadonly(_state)

export const getOrgs = async orgId => {
  if (_state.orgs.length === 0) {
    let { data } = await orgsClient.post('/GetOrgs')
    _state.orgs = data.orgs

    const orgsMap = {}
    data.orgs.forEach(({ id, name }) => {
      orgsMap[id] = name
    })
    _state.orgsMap = orgsMap
  }
  if (orgId) {
    let org = state.orgs.find(o => o.id === orgId)
    _state.org = org
  }
  return _state.orgs
}

export const getOrg = async () => {
  let id = await getOrgId()
  if (id) {
    let { data } = await orgsClient.post('/GetOrg', { id })
    _state.org = data.org
  }
  return _state.orgs
}

export const updateOrg = async org => {
  // check to make sure we are updating the current org
  if (org.id === _state.org?.id) {
    let { data } = await orgsClient.post('/UpdateOrg', org)
    return data
  }
}

export const getBillingReport = async (startDate, endDate, id, min99454DayRequirement = 1) => {
  _state.runningReport = true
  let { data } = await orgsClient.post('/GetBillingReport', {
    startDate,
    endDate,
    min99454DayRequirement,
    id,
  })
  _state.patientReports = data.patientReports
  _state.runningReport = false
  return _state.patientReports
}

export const getInvoices = async (startDate, endDate) => {
  let { data } = await orgsClient.post('/GetInvoices', {
    startDate,
    endDate,
  })

  _state.billingReports = data.enrolledPatients
  return _state.billingReports
}

export const setUsers = users => {
  console.log('Demo users are being set', users)
  _state.orgUsers = users
}

export const getOrgUsers = async () => {
  let id = await getOrgId()
  if (id) {
    let { data } = await orgsClient.post('/GetOrgUsers', { id })
    _state.orgUsers = data.users
  }
  return _state.orgUsers
}
